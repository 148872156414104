import { query } from "@telia/b2x-graphql-request";
import { RECOMMENDATION } from "./queries";
import { buildImageUrl } from "./utils/contentful-image";

type ReturnRecommendation = {
  id: string;
  title: string;
  richPreamble: string;
  image: {
    url: string;
    altText: string;
  };
};

type Recommendation = {
  id: string;
  title: string;
  richPreamble: string;
  secondaryMedia: {
    altText: string;
    focusArea: string;
    image: {
      url: string;
    };
  };
};

type ResultRecommendation = {
  teaserWrapper: {
    teaserList: [Recommendation];
  };
};

function mapRecommendation(t: Recommendation): ReturnRecommendation {
  const imageUrl = buildImageUrl(t.secondaryMedia.image.url, 600, 600, {
    f: t.secondaryMedia.focusArea,
  });
  return {
    id: t.id,
    title: t.title,
    richPreamble: t.richPreamble,
    image: {
      url: imageUrl,
      altText: t.secondaryMedia.altText,
    },
  };
}

export async function fetchOrderPageRecommendations(
  aTscId?: string
): Promise<ReturnRecommendation[]> {
  const queryResult: ResultRecommendation = await query(RECOMMENDATION, { id: "mybusiness-rec" }, {
    aTscId,
  } as HeadersInit);

  return queryResult?.teaserWrapper?.teaserList?.map((teaser) => mapRecommendation(teaser));
}

export async function fetchDashboardRecommendations(
  aTscId?: string
): Promise<ReturnRecommendation[]> {
  const queryResult: ResultRecommendation = await query(RECOMMENDATION, { id: "mybusiness-rec" }, {
    aTscId,
  } as HeadersInit);

  return queryResult?.teaserWrapper?.teaserList?.map((teaser) => mapRecommendation(teaser));
}

type BaseParams = Record<string, string | boolean | number | null>;

type Options = {
  q?: number; // image quality
  f?: string | null;
  fm?: "jpg" | "webp" | "png" | "gif";
  forceJpg?: boolean;
  [prop: string]: number | string | boolean | null | undefined;
};

function generateQueryParams(base: BaseParams, options: Options = {}): string {
  const filteredOptions = Object.keys(options)
    .filter((key) => !!options[key])
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: options[curr],
      }),
      {}
    );

  const params: { [key: string]: any } = { ...base, ...filteredOptions };

  const queryParams: string = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

  return queryParams;
}

export function buildImageUrl(
  url: string,
  width: number,
  height: number,
  options?: Options
): string {
  const base = {
    f: "center",
    fit: "fill",
    w: width,
    h: height,
    fm: "webp",
    q: 70,
  };

  const queryParams = generateQueryParams(base, options);

  return `${url}?${queryParams}`;
}

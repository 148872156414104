import { gql } from "graphql-request";

export const RECOMMENDATION = gql`
  query getTeaserWrapper($id: String!) {
    teaserWrapper(identifier: { value: $id, key: READABLE_ID }) {
      teaserList {
        id
        title
        richPreamble
        secondaryMedia {
          altText
          focusArea
          image {
            url
          }
        }
      }
    }
  }
`;
